<template>
  <div>
    <h1 class="mb-0">Sign Up Your Hotel</h1>
    <p>Enter your email address and password to access admin panel.</p>
    <tab-content id="pills-tabContent-1" class="mt-0">
      <tab-content-item :active="true" id="pills-jwt-fill" aria-labelled-by="pills-jwt-tab-fill">
        {{ loaderState }}
        <sign-up1-form formType="jwt"></sign-up1-form>
      </tab-content-item>
    </tab-content>
  </div>
</template>
<script>

import SignUp1Form from './Forms/SignUp1Form'
export default {
  name: 'SignUp1',
  components: { SignUp1Form },
  data: () => ({}),
  methods: {
  }
}
</script>
